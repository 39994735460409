import CreateTRDAPayload from "@/dto/request/trda/CreateTRDAPayload";
import axios from "axios";
import {TRDA_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import TRDA_DTO from "@/dto/request/trda/TRDA_DTO";
import SavePublicTRDAPayload from "@/dto/request/trda/SavePublicTRDAPayload";

class TRDAService {

    create(payload: CreateTRDAPayload) {
        return axios.post(TRDA_ENDPOINT, payload, {headers: authHeader()});
    }

    getById(id: number) {
        return axios.get<TRDA_DTO>(`${TRDA_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    getByPublicId(id: string) {
        return axios.get<TRDA_DTO>(`${TRDA_ENDPOINT}/p/${id}`);
    }

    savePublic(id: string, payload: SavePublicTRDAPayload) {
        return axios.put(`${TRDA_ENDPOINT}/p/${id}`, payload);
    }

}

export default new TRDAService();