












































































































































import {Component, Vue} from "vue-property-decorator";
import RequestService from "@/services/request/RequestService";
import {RequestMetaDTO} from "@/dto/request/RequestMetaDTO";
import {namespace} from "vuex-class";
import {CreateRequestAPIRequestDTO} from "@/dto/request/CreateRequestAPIRequestDTO";
import {CBSRStatus, FRSOStatus, ITRQStatus, RequestStatus, RequestType, RIDCStatus, W9CRStatus,} from "@/constants/request/RequestsStatuses";
import PhoneInput from "@/components/util/PhoneInput.vue";
import RCCRService from "@/services/request/RCCRService";
import RouteNames from "@/router/RouteNames";
import CompanySearch from "@/components/common/CompanySearch.vue";
import CreateW9CR from "@/components/request/w9cr/CreateW9CR.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import CreateITRQ from "@/components/request/itrq/CreateITRQ.vue";
import {debounce} from "typescript-debounce-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import CreateRER from "@/components/request/rer/CreateRER.vue";
import CreateCBSR from "@/components/request/cbsr/CreateCBSR.vue";
import CreateRIDC from "@/components/request/ridc/CreateRIDC.vue";
import {processError} from "@/utils/ComponentUtils";
import PortalDate from "@/components/common/PortalDate.vue";
import SublimeSearch from "@/components/common/SublimeSearch.vue";
import SublimeEmployeeDTO from "@/dto/sublime/employee/SublimeEmployeeDTO";
import Workspaces from "@/state/Workspaces";
import {WorkspaceType} from "@/dto/auth/Workspace";
import AdminService from "@/services/AdminService";
import CreateCDQR from "@/components/request/cdrq/CreateCDQR.vue";
import {TRDAStatus} from "@/constants/request/TRDAConstants";
import CreateTRDA from "@/components/request/trda/CreateTRDA.vue";

const AppModule = namespace("App");

@Component<RequestList>({
	components: {
    CreateTRDA,
		CreateCDQR,
		SublimeSearch,
		PortalDate,
		CreateCBSR,
		CreateITRQ,
		PaginationComponent,
		CreateW9CR,
		PhoneInput,
		CompanySearch,
		PortalInput,
		CreateRER,
		CreateRIDC,
	},
	computed: {
		RequestType() {
			return RequestType
		}
	},
	watch: {
		assigneeId(n: number | null) {
			this.handleGetEmployee(n, 'selectedAssignee')
			this.loadRequests();
		},
		createdById(n: number | null) {
			this.handleGetEmployee(n, 'selectedCreatedBy')
			this.loadRequests();
		}
	}
})
export default class RequestList extends Vue {

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.State
	private isMobile!: boolean;

	selectedAssignee: SublimeEmployeeDTO | null = null;
	selectedCreatedBy: SublimeEmployeeDTO | null = null;

	requests: Array<RequestMetaDTO> = [];

	createMode = false;
	showFilter = false;

	totalPages = 1;
	pageNumber = 1;
	selectedType: RequestType | null = null;
	selectedStatus: RequestStatus | null = null;
	customerNameFilter: string | null = null;
	assigneeId: number | null = null;
	createdById: number | null = null;
	createdDateFrom: string | null = null;
	createdDateTo: string | null = null;
	section: string | null = null;
	profileId: number | null = null;
	profileType: WorkspaceType | null = null;

	mounted() {
		this.pageNumber = this.$route.query.pageNumber ? Number(this.$route.query.pageNumber) : 1;
		this.selectedType = this.$route.query.selectedType as RequestType ?? null;
		this.selectedStatus = this.$route.query.selectedStatus as RequestStatus ?? null;
		this.customerNameFilter = this.$route.query.customerNameFilter as string ?? null;
		this.assigneeId = this.$route.query.assigneeId ? Number(this.$route.query.assigneeId) : null;
		this.createdById = this.$route.query.createdById ? Number(this.$route.query.createdById) : null;
		this.createdDateFrom = this.$route.query.createdDateFrom as string ?? null;
		this.createdDateTo = this.$route.query.createdDateTo as string ?? null;
		this.section = this.$route.query.section as string ?? null;
		this.profileId = this.$route.query.profileId ? Number(this.$route.query.profileId) : null;
		this.profileType = this.$route.query.profileType as WorkspaceType ?? null;
		this.handleGetEmployee(this.assigneeId, 'selectedAssignee');
		this.handleGetEmployee(this.createdById, 'selectedCreatedBy');
		this.loadRequests();
	}

	type: RequestType | null = null;

	createRCCRPayload: CreateRequestAPIRequestDTO = {
		customerNamePlaceholder: "",
		email: "",
		phone: "",
		desiredCallTime: null
	}

	@debounce(500)
	search() {
		this.loadRequests();
	}

	loadRequests() {
		this.startLoading();
		if (this.$route.name === RouteNames.HR_REQUESTS) {
			this.profileId = Workspaces.getCurrent.id;
			this.profileType = Workspaces.getCurrent.type;
		}
		RequestService.getAllRequests(
			this.pageNumber,
			this.selectedType,
			this.selectedStatus,
			this.customerNameFilter,
			this.assigneeId,
			this.createdById,
			this.createdDateFrom,
			this.createdDateTo,
			this.section,
			this.profileId,
			this.profileType,
		)
			.then(
				resp => {
					this.requests = resp.data.content;
					this.pageNumber = resp.data.number + 1;
					this.totalPages = resp.data.totalPages;
					this.stopLoading();
				},
				error => {
					processError(error, this);
					this.stopLoading();
				}
			).then(() => {
			this.applyRouteParams();
		})
	}

	handleGetEmployee (
		id: string | number | null,
		selectedGoal: keyof Pick<RequestList, 'selectedAssignee' | 'selectedCreatedBy'>
	) {
		if (id) {
			AdminService.getEmployee(id).then(
				ok => {
					this[selectedGoal] = ok.data;
				},
				err => {
					processError(err, this);
				}
			)
		}
	}

	private async applyRouteParams() {
		const dict = {
			pageNumber: this.pageNumber ? String(this.pageNumber) : undefined,
			selectedType: this.selectedType ?? undefined,
			selectedStatus: this.selectedStatus ?? undefined,
			customerNameFilter: this.customerNameFilter ?? undefined,
			assigneeId: this.assigneeId ? String(this.assigneeId) : undefined,
			createdById: this.createdById ? String(this.createdById) : undefined,
			createdDateFrom: this.createdDateFrom ?? undefined,
			createdDateTo: this.createdDateTo ?? undefined,
			section: this.section ?? undefined,
			profileId: this.profileId ? String(this.profileId) : undefined,
			profileType: this.profileType ?? undefined,
		}

		await this.$router.replace({
			query: dict
		})
	}

	create() {
		this.startLoading();
		RCCRService.createRequest(this.createRCCRPayload).then(
			resp => {
				this.createMode = false;
				this.stopLoading();
				this.loadRequests();
			},
			error => {
				processError(error, this);
				this.stopLoading();
			}
		)
	}

	gotoRequest(request: RequestMetaDTO) {
		switch (request.type) {
			case RequestType.CBSR:
				this.$router.push({name: RouteNames.CBSR, params: {id: `${request.targetId}`}});
				break;
			case RequestType.W9CR:
				this.$router.push({name: RouteNames.W9CR, params: {id: `${request.targetId}`}});
				break;
			case RequestType.FRSO:
				this.$router.push({name: RouteNames.FRSO, params: {id: `${request.targetId}`}});
				break;
			case RequestType.ITRQ:
				this.$router.push({name: RouteNames.ITRQ, params: {id: `${request.targetId}`}});
				break;
			case RequestType.RER:
				this.$router.push({name: RouteNames.RER, params: {id: `${request.targetId}`}});
				break;
			case RequestType.RIDC:
				this.$router.push({name: RouteNames.RIDC, params: {id: `${request.targetId}`}});
				break;
			case RequestType.IAAR:
				this.$router.push({name: RouteNames.IAAR, params: {requestId: `${request.targetId}`}});
				break;
			case RequestType.CDRQ:
				this.$router.push(({name: RouteNames.CDRQ, params: {id: `${request.id}`}}))
        break;
      case RequestType.TRDA:
				this.$router.push(({name: RouteNames.TRDA, params: {id: `${request.id}`}}))

		}
	}

	reload() {
		this.createMode = false;
		this.loadRequests();
	}

	changePage(page: number) {
		this.pageNumber = page;
		this.loadRequests();
	}

	get getStatuses(): Array<RequestStatus> {
		switch (this.selectedType) {
			case RequestType.CBSR:
				return Object.values(CBSRStatus);
			case RequestType.W9CR:
				return Object.values(W9CRStatus);
			case RequestType.FRSO:
				return Object.values(FRSOStatus);
			case RequestType.ITRQ:
				return Object.values(ITRQStatus);
			case RequestType.RIDC:
				return Object.values(RIDCStatus);
      case RequestType.TRDA:
        return Object.values(TRDAStatus);
			default:
				return [];
		}
	}

	onTypeChanged() {
		this.selectedStatus = null;
		this.pageNumber = 1;
		this.totalPages = 1;
		this.loadRequests();
	}

	onStatusChanged() {
		this.pageNumber = 1;
		this.totalPages = 1;
		this.loadRequests();
	}

	get getFilteredParamLine(): string {
		const params: Array<string> = [];
		if (this.selectedType) {
			params.push("type: " + this.selectedType);
		}
		if (this.selectedStatus) {
			params.push("status: " + this.selectedStatus);
		}
		if(this.customerNameFilter) {
			params.push("customer name: " + this.customerNameFilter);
		}
		if(this.assigneeId) {
			params.push("assignee: " + this.assigneeId);
		}
		if(this.createdById) {
			params.push("created by: " + this.createdById);
		}
		if(this.createdDateFrom) {
			params.push("updated: " + this.createdDateFrom);
		}
		if(this.createdDateTo) {
			params.push("created: " + this.createdDateTo);
		}

		return params.length > 0 ? ` (${params.join(", ")})` : "";
	}

	getIconByCustomerType(r: RequestMetaDTO): string {
		if (r.company && !r.person) {
			return 'building';
		}
		else {
			return 'user';
		}
	}

}
