import {CounterpartyType} from "@/constants/CounterpartyType";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class CreateTRDAPayload {
    customerId: number | null = null;
    counterpartyType: CounterpartyType | null = null;
    year: number | null = null;
    accompanyingText: string | null = null;
    documents: Array<FileMetaDTO> = [];
    language: 'EN' | 'RU' = 'EN';
}