


















import {Vue, Component} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import {OptionsBuilder} from "@/components/common/SelectOption";
import CreateTRDAPayload from "@/dto/request/trda/CreateTRDAPayload";
import {CounterpartyType} from "@/constants/CounterpartyType";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import PersonSearch from "@/components/common/PersonSearch.vue";
import {IProfile} from "@/dto/IProfile";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import TRDAService from "@/services/request/TRDAService";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";

@Component({
  computed: {
    CounterpartyType() {
      return CounterpartyType
    },
    OB() {
      return OptionsBuilder
    }
  },
  components: {SingleFileHolder, PortalTextarea, PersonSearch, SimpleCompanySearch, PortalSelect}
})
export default class CreateTRDA extends Vue {

  payload = new CreateTRDAPayload();

  newFile: File | null = null;

  onCustomerChanged(customer: IProfile): void {
    this.payload.customerId = customer.id;
  }

  onFileUploaded(file: FileMetaDTO): void {
    this.payload.documents.push(file);
    this.newFile = null;
  }

  get allFieldsFilled(): boolean {
    return !!this.payload.customerId && !!this.payload.year && this.payload.language && !!this.payload.accompanyingText && this.payload.documents.length > 0;
  }

  create(): void {
    Application.startLoading();
    TRDAService.create(this.payload).then(
        ok => {
          Application.stopLoading();
          this.$emit("create");
        },
        err => {
          Application.stopLoading();
          processError(err, this);
        }
    )
  }

}
